@import "../../css/common/variables.scss";
@import "../../css/common/colors.scss";

.Form {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background: $color-item-background;
  margin-bottom: 1rem;
}

.form-input-container {
  display: flex;
  margin-right: 1rem;
}

.form-input:first-child {
  margin-right: 1rem;
}

.form-input Input {
  margin-top: 0.3rem;
}

.form-button-container button {
  border-radius: 20px;
  background-color: $color-title;
  border: none;
  color: $color-default;
  font-size: 25px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90px;
  height: 38px;
}

@media (max-width: $mobile-max-width) {
  .Form {
    flex-direction: column;
    align-content: stretch;
  }

  .form-input-container {
    margin-right: 0;
    justify-content: space-between;
    margin-bottom: 0.5rem;
  }

  .form-button-container {
    width: 100%;
  }

  .form-button-container button {
    width: 100%;
  }
}
