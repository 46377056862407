@import "../common/fonts.scss";
@import "../common/colors.scss";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Nunito", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $color-default;
  background: $color-default-background;
}

h1 {
  color: $color-title;
}
