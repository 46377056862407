@import "./../../css/common/colors.scss";
@import "./../../css/common/variables.scss";

.Card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: $color-item-background;
  padding: 0.5rem 1rem;
  border-bottom: 1px solid $color-default-background;
}
.Card--text {
  padding-right: 20px;
}
.Card--button {
  min-width: 90px;
  display: flex;
  justify-content: space-between;
}
.Card--button button {
  background: #f5f6f7;
  padding: 0.5rem 0.5rem;
  font-size: 20px;
  border-radius: 20px;
  cursor: pointer;
  display: flex;
}
.Card--button__delete {
  border: 1px solid $color-delete;
  color: $color-delete;
}
.Card--button__completed {
  border: 1px solid $color-confirm;
  color: $color-confirm;
}
.Card--button__not-completed {
  border: 1px solid $color-disable;
  color: $color-disable;
}
.line-through {
  text-decoration: line-through;
  color: $color-disable !important;
}

@media (max-width: $mobile-max-width) {
  .Card {
  }

  .Card--button {
    min-width: 38px;
    min-height: 100px;
    flex-direction: column;
    justify-items: end;
    justify-content: space-evenly;
  }

  .Card--text {
    padding-right: 10px;
  }

  .Card--text h1 {
    font-size: x-large;
  }
}
