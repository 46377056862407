@import "common/variables.scss";

.App {
  max-width: $max-width;
  margin: 4rem auto;
}
.App > h1 {
  text-align: center;
  margin: 1rem 0;
}

@media (max-width: $mobile-max-width) {
  .App {
    margin: 0 auto;
  }
}
