@import "./../../css/common/variables.scss";
@import "./../../css/common/colors.scss";

.custom-input {
  width: 250px;
  display: block;
  padding: 0.5rem 1rem;
  border-radius: 10px;
  border: 1px solid $color-title;
  background-color: $color-default;
  font-size: 16px;
}

@media (max-width: $mobile-max-width) {
  .custom-input {
    width: 100%;
  }
}
